@if (loading()) {
  <div class="loading">
    <app-loading />
    <app-loading-logo />
  </div>
}

<div
  #dialogContainer
  style="height: 0">
</div>

<div
  class="flex-grow"
  [style.opacity]="firstLoaded() ? 1 : 0">
  <app-header />
  <router-outlet />
</div>

<app-footer [style.opacity]="firstLoaded() ? 1 : 0" />

@defer (on idle) {
  <app-sprite-svg />
}
