<app-dialog-container
  [state]="state"
  (close)="closeModal()"
  [classNames]="'standard'">
  <div class="flex flex-col items-center gap-5">
    <img
      [src]="env.assets + '/images/reg-complete@2x.jpg'"
      alt="Thanks for registration"
      class="w-52 tablet:w-72" />

    <h1 class="uppercase font-bold text-2xl tablet:text-3xl text-center">
      Thanks for registration
    </h1>

    <p class="text-sm tablet:text-base font-light">
      @if (in120sec()) {
        Check your inbox for a confirmation email with the link to the Splits Workshop and your FREE
        Back Flexibility Class. After the workshop ends on {{ startDate() }}, we’ll email you a
        recording that will be available for 48 hours.
      } @else {
        Check your inbox for a confirmation email with the link to the Splits Workshop. After the
        workshop ends on {{ startDate() }}, we’ll email you a recording that will be available for
        48 hours.
      }
    </p>

    <p class="bg-gray-light px-4 py-2.5 font-light text-sm tablet:text-base">
      <span class="font-medium">Important!</span>
      Please check your spam folder if you can’t locate the emails And if you’re not currently using
      STRETCHIT, try it free!
    </p>

    <div class="h-20 tablet:h-0"></div>

    <button
      type="button"
      (click)="closeModal()"
      class="st-btn st-btn-dark w-full">
      Ok
    </button>
  </div>
</app-dialog-container>
