import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogState } from '../../../../../dataset/Dialog';
import { NgClass, NgStyle } from '@angular/common';

const wildcard = '* => *';
const animationTimings = '230ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  animations: [
    trigger('blackout', [
      state(
        'open',
        style({
          opacity: 0.3,
        })
      ),
      state(
        'close',
        style({
          opacity: 0,
        })
      ),
      transition(wildcard, animate(animationTimings)),
    ]),
    trigger('box', [
      state(
        'open',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      state(
        'close',
        style({
          transform: 'translateY(100%)',
          opacity: 0,
        })
      ),
      transition(wildcard, animate(animationTimings)),
    ]),
    trigger('close', [
      state(
        'open',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      state(
        'close',
        style({
          transform: 'translateY(100%)',
          opacity: 0,
        })
      ),
      transition(wildcard, animate(animationTimings)),
    ]),
  ],
  imports: [NgStyle, NgClass],
})
export class DialogContainerComponent {
  readonly state = input.required<DialogState>();
  readonly backdropClose = input(true);

  readonly redirectTo = input<string>('');
  readonly disableClose = input(false);
  readonly classNames = input<string>('');
  readonly padding = input('60px 70px 55px 70px');

  readonly close = output<unknown>();

  onClose($event: MouseEvent): void {
    if (!this.redirectTo()) $event.preventDefault();

    this.close.emit($event);
  }
}
