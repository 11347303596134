import { Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { Dialog, DialogCloseCallback } from '../../../../../dataset/Dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  viewContainerRef: ViewContainerRef | null = null;
  animationDuration = 230;

  isDialogOpen = false;

  constructor() {}

  registerViewContainer(vcf: ViewContainerRef): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    this.viewContainerRef = vcf;
  }

  open<T>(type: Type<unknown>, data?: unknown, injector?: Injector): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.viewContainerRef) {
        return;
      }

      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(type, { injector });
      const ref = componentRef.instance as Dialog;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      ref.state = 'open';
      ref.data = data;
      this.isDialogOpen = true;

      const close = (complete: DialogCloseCallback) => {
        ref.state = 'close';

        setTimeout(() => {
          this.viewContainerRef?.remove();
          this.isDialogOpen = false;
          complete();
        }, this.animationDuration);
      };

      // @ts-ignore
      ref.resolve = (_data: T) => {
        close(() => {
          resolve(_data);
        });
      };

      ref.reject = err => {
        close(() => {
          reject(err);
        });
      };
    });
  }
}
