import { Routes } from '@angular/router';
import { WorkshopResolver } from './core/stores/workshop.store';
import { AccessTokenGuard } from './core/guards/access-token.guard';
import { RegisterGuard } from './core/guards/register.guard';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/workshop/workshop.component').then(c => c.WorkshopComponent),
    resolve: {
      workshop: WorkshopResolver,
    },
    canActivate: [AccessTokenGuard],
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./core/components/empty/empty.component').then(c => c.EmptyComponent),
    canActivate: [AccessTokenGuard, RegisterGuard],
  },
  {
    path: 'auth',
    loadComponent: () => import('./pages/auth/auth.component').then(c => c.AuthComponent),
    children: [
      {
        path: 'sign-in',
        loadComponent: () =>
          import('./pages/auth/sign-in/sign-in.component').then(c => c.SignInComponent),
      },
      {
        path: 'sign-up',
        loadComponent: () =>
          import('./pages/auth/sign-up/sign-up.component').then(c => c.SignUpComponent),
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import('./pages/auth/forgot-password/forgot-password.component').then(
            c => c.ForgotPasswordComponent
          ),
      },
      {
        path: '**',
        redirectTo: 'sign-up',
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
