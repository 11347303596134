import { CanActivateFn, Router } from '@angular/router';
import { UserStore } from '../stores/user.store';
import { inject } from '@angular/core';
import { WorkshopStore } from '../stores/workshop.store';
import { StorageService } from '../services/storage.service';
import { DialogService } from '../components/ui/dialog/services/dialog.service';
import { ThanksForRegistrationModalComponent } from '../components/modals/thanks-for-registration-modal/thanks-for-registration-modal.component';

export const RegisterGuard: CanActivateFn = async route => {
  const userStore = inject(UserStore);
  const workshopStore = inject(WorkshopStore);
  const router = inject(Router);
  const storage = inject(StorageService);
  const dialogService = inject(DialogService);
  const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');

  if (apiKey) {
    storage.setItem('access_token', apiKey.replace(/\s/g, '+'));
  }

  const accessToken = storage.getItem('access_token');
  if (!accessToken) {
    return router.parseUrl('/auth/sign-up');
  }

  if (!userStore.user()) {
    await userStore.loadUser(true);
  }
  let workshop = workshopStore.workshop();
  if (!workshop) {
    workshop = await workshopStore.loadWorkshop();
  }

  if (workshop) {
    const firstVisitTime = storage.getItem(`workshop_${workshop.id}_first_visit`) || '0';
    const registerStartTime =
      storage.getItem(`workshop_${workshop.id}_register_start`) || String(Date.now());

    const in120sec = Number(registerStartTime) - Number(firstVisitTime) <= 120_000;

    if (workshop.registered) {
      dialogService
        .open(ThanksForRegistrationModalComponent, {
          in120sec: in120sec,
          startDate: workshop.start_date,
        })
        .then();
      return router.parseUrl('/');
    }

    await workshopStore.register(in120sec);
    dialogService
      .open(ThanksForRegistrationModalComponent, {
        in120sec: in120sec,
        startDate: workshop.start_date,
      })
      .then();
  }

  return router.parseUrl('/');
};
