import { Component, inject, OnInit, signal } from '@angular/core';
import { IDialog } from '../../ui/dialog/IDialog';
import { Dialog } from '../../ui/dialog/Dialog';
import { DialogContainerComponent } from '../../ui/dialog/dialog-container/dialog-container.component';
import { ENVIRONMENT } from '../../../tokens/env.token';
import dayjs from 'dayjs';

@Component({
  selector: 'app-thanks-for-registration-modal',
  imports: [DialogContainerComponent],
  templateUrl: './thanks-for-registration-modal.component.html',
  styles: ``,
  standalone: true,
})
export class ThanksForRegistrationModalComponent extends Dialog implements IDialog, OnInit {
  readonly env = inject(ENVIRONMENT);

  readonly in120sec = signal(false);
  readonly startDate = signal('');

  closeModal(): void {
    this.resolve();
  }

  ngOnInit() {
    this.in120sec.set(this.data.in120sec ?? false);
    this.startDate.set(
      dayjs(this.data.startDate ?? '')
        .tz('America/New_York')
        .format('MMMM D')
    );
  }
}
