import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { UserStore } from '../stores/user.store';

export const AuthGuard: CanActivateFn = async route => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  const storage = inject(StorageService);
  const apiKey = route.queryParamMap.get('ApiKey') || route.queryParamMap.get('apiKey');

  if (apiKey) {
    storage.setItem('access_token', apiKey.replace(/\s/g, '+'));
  }

  const accessToken = storage.getItem('access_token');
  if (accessToken && !userStore.user()) {
    await userStore.loadUser(true);
  }
  if (userStore.user()) {
    return router.parseUrl('/');
  }

  return true;
};
