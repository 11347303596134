<div
  role="presentation"
  class="blackout"
  (click)="backdropClose() && onClose($event)"
  [@blackout]="state()">
</div>

<div
  class="box st-overflow-x"
  [ngStyle]="{ padding: padding() }"
  [@box]="state()"
  [ngClass]="['box', classNames() || '']">
  @if (!disableClose()) {
    <a
      class="close-btn"
      (click)="onClose($event)"
      [href]="redirectTo() || '#'">
      <img
        src="/assets/images/icons/close-black.svg"
        alt="" />
    </a>
  }

  <div class="content st-h-fill">
    <ng-content />
  </div>
</div>
