import { Component, inject } from '@angular/core';
import { ENVIRONMENT } from '../../../tokens/env.token';
import { DatePipe, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-footer',
  imports: [NgTemplateOutlet, DatePipe],
  templateUrl: './footer.component.html',
  styles: `
    :host {
      .footer {
        padding: 35px 0;
        font-weight: 300;

        @media (max-width: 599px) {
          padding: 15px 0;
        }

        &-title {
          font-size: 14px;
          line-height: 30px;
          color: var(--st-color-dark-grey);
        }

        &-menu {
          @media (max-width: 599px) {
            flex-wrap: wrap;

            li {
              padding-bottom: 10px;
            }
          }

          li {
            font-size: 14px;
            line-height: 22px;

            @media (max-width: 991px) {
              font-size: 13px;
            }

            @media (max-width: 599px) {
              font-size: 14px;
            }

            a {
              color: var(--st-color-dark-grey);
            }
          }
        }
      }

      .icon-title {
        width: 34px;
        height: 34px;
        background-image: url('https://d2bkxke45bupbc.cloudfront.net/assets/img/NewRebrend/Group+(58).png');
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 767px) {
          width: 31px;
          height: 31px;
        }
      }
    }
  `,
  standalone: true,
})
export class FooterComponent {
  readonly env = inject(ENVIRONMENT);

  date: Date = new Date();
  links = [
    {
      label: 'Support',
      href: 'https://support.stretchitapp.com',
      target: '_blank',
    },
    {
      label: 'Terms of Service',
      href: 'https://web.stretchitapp.com/app/page/terms-of-service',
      target: '_blank',
    },
  ];
}
